import { Box, Container, Grid, GridItem, Text } from '@chakra-ui/react';
import { nodeParser } from '@kontent-ai/delivery-node-parser';
import { createRichTextHtmlResolver } from '@kontent-ai/delivery-sdk';
import { formatTags, toTitleCase } from 'helpers/textCase';
import cacheData from 'memory-cache';
import { useRouter } from 'next/router';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { withTranslations } from 'store/translations';
import { moduleMaxWidth } from 'themes/overrides';
import { getHost } from 'utils/getHost';
import { getOverviewItemForOverviewBlock } from 'utils/getOverviewItem';

import { resolveHtml } from '../../helpers/htmlResolver';
import { colorScheme } from '../../lib/colorScheme';
import { OverviewSpotAutomaticNoTabs as OverviewModel } from '../../models/content-types/overview';
import { OverviewItemModel } from '../../utils/types/overviewItemModel';
import { OverviewItem } from './OverviewItem';

export function classNameToTagName(str: string): string {
  let newStr = '';
  for (let i = 0; i < str.length; i++) {
    if (str[i] === str[i].toUpperCase()) {
      if (i > 0) {
        newStr += '_';
      }
      newStr += str[i].toLowerCase();
    } else {
      newStr += str[i];
    }
  }
  return `tags__${newStr}`;
}

export const mainSubtypeForOverviewAutomatic = {
  project: 'tagsSector',
  insight: 'tagsThemeTopic',
  product: 'tagsSector',
  segment: 'tagsSector',
  sector: 'tagsSector',
  service_line: 'tagsServiceLine',
  news: 'tagsSector',
};

interface OverviewProps {
  model: OverviewModel;
  subcategoryTags?: string[];
  parentPageCodename?: string;
}

const getNumberOfGridColumns = (numberOfItems: number) => {
  if (numberOfItems >= 2) {
    return numberOfItems % 2 === 0 && numberOfItems < 6 ? 2 : 3;
  }
  return 0;
};

const getTagName = (overviewType: string, model: OverviewModel) => {
  if (overviewType.includes('service')) {
    if (model.elements.overviewSpotAutomaticTagsServiceLine.value.length) return 'tagsServiceLine';
    return 'tagsSector';
  }
  return mainSubtypeForOverviewAutomatic[overviewType] as string;
};

const normalizeOverviewSpots = (model: OverviewModel) => {
  const overviewType = model.elements.overviewType.value[0].codename;
  const contentPages = model.elements.contentPages.linkedItems;
  const subcategoryCodename = formatTags(getTagName(overviewType, model));
  const subcategoryTags = model.elements[
    `overviewSpotAutomatic${toTitleCase(getTagName(overviewType, model))}`
  ]?.value?.map((value) => value.codename as string) ?? [''];
  return { overviewType, contentPages, subcategoryCodename, subcategoryTags };
};

export const OverviewBlock: FunctionComponent<OverviewProps> = ({ model, parentPageCodename }) => {
  const { locale } = useRouter();
  const router = useRouter();
  const translations = withTranslations();
  const numberOfItems = Number(model.elements.numberOfItems.value[0]?.name);
  const backgroundColor =
    colorScheme[model.elements.backgroundColor?.value[0].codename as keyof typeof colorScheme]?.base ?? 'white';
  const headline = createRichTextHtmlResolver(nodeParser).resolveRichText({
    element: model.elements.headline,
  }).html;
  const headlineInfo = resolveHtml(headline);
  const { overviewType, contentPages, subcategoryCodename, subcategoryTags } = normalizeOverviewSpots(model);
  const overviewItems = contentPages.map((contentPage) =>
    getOverviewItemForOverviewBlock(contentPage, translations, router.locale),
  );
  const [items, setItems] = useState<OverviewItemModel[]>(overviewItems.length >= 2 ? overviewItems : []);
  const [numberOfGridColumns, setNumberOfGridColumns] = useState<number>(getNumberOfGridColumns(items?.length ?? 0));
  const host = typeof window !== 'undefined' ? getHost(window) : '';

  const fetchPages = async () => {
    let pages;
    const resultWithTags = await fetch(
      `${host}/api/contentPagesWithTags?numberOfItems=${numberOfItems}&overviewType=${overviewType}&subcategoryCodename=${subcategoryCodename}&subcategoryTags=${subcategoryTags.join(
        ',',
      )}&parentPageCodename=${parentPageCodename}&languageParameter=${locale}`,
    ).then((response) => response.json());
    if (resultWithTags.pages?.length > 1) {
      pages = resultWithTags.pages;
    } else {
      const resultWithoutTags = await fetch(
        `${host}/api/contentPages?numberOfItems=${numberOfItems}&overviewType=${overviewType}&parentPageCodename=${parentPageCodename}&languageParameter=${locale}`,
      ).then((response) => response.json());
      pages = resultWithoutTags.pages;
    }
    setItems(pages?.map((page) => getOverviewItemForOverviewBlock(page, translations, router.locale)));
  };

  useEffect(() => {
    if (items.length === 0) {
      fetchPages();
    }
  }, []);
  useEffect(() => {
    cacheData.put('translation', translations);
  }, [locale, translations.locale]);
  useEffect(() => {
    cacheData.put('translation', translations);

    fetchPages();
  }, [translations.locale, translations.isReady, router.isReady]);
  useEffect(() => {
    setNumberOfGridColumns(getNumberOfGridColumns(items?.length ?? 0));
  }, [items]);

  return (
    <Box id={model.system.codename} w='100vw' mx='auto' maxW={moduleMaxWidth} bg={backgroundColor}>
      <Container
        display='flex'
        flexDir='column'
        py={{ base: 'm', md: 'l' }}
        px={{ base: 'xs', md: 'l' }}
        gap={{ base: 's', md: 'l' }}
      >
        <Text as='h2' textStyle={{ base: 'mobile.body.l', md: `desktop.h5` }}>
          {headlineInfo.body}
        </Text>
        <Grid
          as='ul'
          listStyleType='none'
          templateColumns={{
            base: 'repeat(1, 1fr)',
            lg: `repeat(${numberOfGridColumns}, 1fr)`,
          }}
          templateRows={{ base: ``, md: `` }}
          gap={{ base: 'xs', md: 'l' }}
        >
          {items?.map((item, index) => (
            <GridItem key={index} as='li'>
              <OverviewItem model={item} />
            </GridItem>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};
